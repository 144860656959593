import React from "react";
import PropTypes from "prop-types";
// Redux
import { useSelector } from "react-redux";
import { getAccessToken } from "../redux/modules/steps";
import { Link } from "react-router-dom";
import { getMessageReject } from "../helpers/messagesSwal";

export const Card = ({ en_proceso, nit, fecha, hora, estado, path_pdf, mensaje }) => {
  const token = useSelector(getAccessToken);

  const handleOnClick = () => {
    getMessageReject(nit, fecha, hora, estado, mensaje);
  };

  return (
    <>
      {en_proceso ? (
        <div className="card">
          <div className="stateInProgress"></div>
          <div className="information">
            <span>Consulta con NIT</span>
            <p>{nit}</p>
          </div>
          <div className="information">
            <span>Fecha de consulta</span>
            <p>{fecha}</p>
          </div>
          <div className="information">
            <span>Hora de consulta</span>
            <p>{hora}</p>
          </div>
          <div className="information">
            <span>Estado</span>
            <p>{estado}</p>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="stateFinished"></div>
          <div className="information">
            <span>Consulta con NIT</span>
            <p>{nit}</p>
          </div>
          <div className="information">
            <span>Fecha de consulta</span>
            <p>{fecha}</p>
          </div>
          <div className="information">
            <span>Hora de consulta</span>
            <p>{hora}</p>
          </div>
          <div className="information">
            <span>Estado</span>
            <p>{estado}</p>
          </div>

          {!mensaje ? (
            <div className="cardLinks">
              <a target="_blank" rel="noreferrer" href={`${path_pdf}&authorization=Bearer ${token}`}>
                Ver
              </a>
              <a target="_blank" rel="noreferrer" download href={`${path_pdf}&authorization=Bearer ${token}`}>
                Descargar PDF
              </a>
            </div>
          ) : (
            <div className="cardLinks">
              <p className="rechazado_link" onClick={handleOnClick}>
                ¿Por qué fue rechazado?
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const CardAdmin = ({ en_proceso, tipo_consulta, usuario, id, fecha, hora, estado, path_pdf, nombre_empresa }) => {
  const token = useSelector(getAccessToken);

  return (
    <>
      {en_proceso ? (
        <div className="card">
          <div className="stateInProgress"></div>
          <div className="information">
            <span>Tipo de consulta</span>
            <p>{tipo_consulta}</p>
          </div>
          <div className="information">
            <span>Usuario</span>
            <p>{usuario}</p>
          </div>
          <div className="information">
            <span>Nombre Empresa</span>
            <p>{nombre_empresa}</p>
          </div>
          <div className="information">
            <span>Fecha de consulta</span>
            <p>{fecha}</p>
          </div>
          <div className="information">
            <span>Hora de consulta</span>
            <p>{hora}</p>
          </div>
          <div className="information">
            <p>
              <Link to={`/adminRevision/${id}`}>Hacer revisión</Link>
            </p>
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="stateFinished"></div>
          <div className="information">
            <span>Tipo de consulta</span>
            <p>{tipo_consulta}</p>
          </div>
          <div className="information">
            <span>Usuario</span>
            <p>{usuario}</p>
          </div>
          <div className="information">
            <span>Fecha de consulta</span>
            <p>{fecha}</p>
          </div>
          <div className="information">
            <span>Hora de consulta</span>
            <p>{hora}</p>
          </div>
          <div className="information">
            <span>Estado</span>
            <p>{estado}</p>
          </div>
          {estado !== "Rechazado" && (
            <div className="cardLinks">
              <a target="_blank" rel="noreferrer" href={`${path_pdf}&authorization=Bearer ${token}`}>
                Ver
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

CardAdmin.propTypes = {
  en_proceso: PropTypes.bool.isRequired,
  tipo_consulta: PropTypes.string,
  usuario: PropTypes.string,
  id: PropTypes.string,
  fecha: PropTypes.string,
  hora: PropTypes.string,
  estado: PropTypes.string,
  path_pdf: PropTypes.string,
};

Card.propTypes = {
  en_proceso: PropTypes.bool.isRequired,
  nit: PropTypes.string,
  fecha: PropTypes.string,
  hora: PropTypes.string,
  estado: PropTypes.string,
  path_pdf: PropTypes.string,
};
