import { createSelector } from 'reselect';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

// TYPES
const CONTINUE_STEP_ONE_NATURAL = '[STEP]/CONTINUE_STEP_ONE_NATURAL';
const BACK_STEP_TWO_NATURAL = '[STEP]/BACK_STEP_TWO_NATURAL';
const CONTINUE_STEP_ONE_JURIDICA = '[STEP]/CONTINUE_STEP_ONE_JURIDICA';
const CONTINUE_STEP_TWO_JURIDICA = '[STEP]/CONTINUE_STEP_TWO_JURIDICA';
const BACK_STEP_TWO_JURIDICA = '[STEP]/BACK_STEP_TWO_JURIDICA';
const BACK_STEP_THREE_JURIDICA = '[STEP]/BACK_STEP_THREE_JURIDICA';
const SET_FILE_CAMARA_COMERCIO = '[STEP]/SET_FILE_CAMARA_COMERCIO';
const SET_FILE_CEDULA = '[STEP]/SET_FILE_CEDULA';
const SET_FILE_FIRMA = '[STEP]/SET_FILE_FIRMA';
const SET_DROPDOWN_LIST_DATA = '[STEP]/SET_DROPDOWN_LIST_DATA';
const GET_DROPDOWN_LIST_DATA = '[STEP]/GET_DROPDOWN_LIST_DATA';
const SET_REQUEST_CONSULTA = '[STEP]/SET_REQUEST_CONSULTA';
const SUCCESS_CONSULTA_RESPONSE = '[STEP]/SUCCESS_CONSULTA_RESPONSE';
const ERROR_CONSULTA_RESPONSE = '[STEP]/ERROR_CONSULTA_RESPONSE';
const RESET_ERROR = '[STEP]/RESET_ERROR';
const RESET_RESPONSE = '[STEP]/RESET_RESPONSE';

const initialState = {
    current_step_natural: 1,
    current_step_juridica: 1,
    dropdown_data: [],
    loading: false,
    isSuccess: false,
    response_consulta: null,
    error: null
}

// REDUCER
export default function stepsReducer(state = initialState, action) {
    switch (action.type) {
        case CONTINUE_STEP_ONE_NATURAL:
            return {
                ...state,
                current_step_natural: 2,
            };
        case BACK_STEP_TWO_NATURAL:
            return {
                ...state,
                current_step_natural: 1,
            };
        case CONTINUE_STEP_ONE_JURIDICA:
            return {
                ...state,
                current_step_juridica: 2,
            };
        case CONTINUE_STEP_TWO_JURIDICA:
            return {
                ...state,
                current_step_juridica: 3
            };
        case BACK_STEP_TWO_JURIDICA:
            return {
                ...state,
                current_step_juridica: 1
            };
        case BACK_STEP_THREE_JURIDICA:
            return {
                ...state,
                current_step_juridica: 2
            };
        case SET_FILE_CAMARA_COMERCIO:
            return {
                ...state,
                file_camara_comercio: action.payload,
            };
        case SET_FILE_CEDULA:
            return {
                ...state,
                file_cedula: action.payload,
            };
        case SET_FILE_FIRMA:
            return {
                ...state,
                file_firma: action.payload,
            };
        case SET_DROPDOWN_LIST_DATA:
            return {
                ...state,
                dropdown_data: action.payload,
            };
        case SUCCESS_CONSULTA_RESPONSE:
            return {
                ...state,
                response_consulta: action.payload,
                error: null,
                isSuccess: true,
                loading: false
            };
        case ERROR_CONSULTA_RESPONSE:
            return {
                ...state,
                error: action.payload,
                response_consulta: null,
                loading: false
            };
        case SET_REQUEST_CONSULTA:
            return {
                ...state,
                loading: true
            }
        case RESET_ERROR:
            return {
                ...state,
                error: null,
            }
        case RESET_RESPONSE:
            return initialState
        default:
            return state
    }
}


// SELECTORS
export const getSteps = state => state.steps;
const getAuth = state => state.auth;

export const getAccessToken = createSelector(
    getAuth,
    ({ access_token }) => (access_token)
);

// ACTIONS
export const continueStepOneNatural = () => ({ type: CONTINUE_STEP_ONE_NATURAL });
export const continueStepOneJuridica = () => ({ type: CONTINUE_STEP_ONE_JURIDICA });
export const backStepTwoNatural = () => ({ type: BACK_STEP_TWO_NATURAL });
export const continueStepTwoJuridica = () => ({ type: CONTINUE_STEP_TWO_JURIDICA });
export const backStepTwoJuridica = () => ({ type: BACK_STEP_TWO_JURIDICA });
export const backStepThreeJuridica = () => ({ type: BACK_STEP_THREE_JURIDICA });
export const setFileCedula = payload => ({ type: SET_FILE_CEDULA, payload });
export const setFileFirma = payload => ({ type: SET_FILE_FIRMA, payload });
export const setFileCamaraComercio = payload => ({ type: SET_FILE_CAMARA_COMERCIO, payload });
export const getDropDownList = () => ({ type: GET_DROPDOWN_LIST_DATA });
export const setRequestConsulta = payload => ({ type: SET_REQUEST_CONSULTA, payload });
export const resetErrorSteps = () => ({ type: RESET_ERROR });
export const resetResponse = () => ({ type: RESET_RESPONSE });
const setDropDownList = payload => ({ type: SET_DROPDOWN_LIST_DATA, payload });
const consultaSuccessResponse = payload => ({ type: SUCCESS_CONSULTA_RESPONSE, payload });
const consultaErrorResponse = payload => ({ type: ERROR_CONSULTA_RESPONSE, payload });

//EPICS
export const getDropDownListEpic = (action$, state$) => action$.pipe(
    ofType(GET_DROPDOWN_LIST_DATA),
    mergeMap(() => {
        const token = getAccessToken(state$.value);
        return ajax.getJSON(`${process.env.REACT_APP_URL_API}/drop_down_list`, { Authorization: `Bearer ${token}` }).pipe(
            map((response) => setDropDownList(response)),
            catchError(({ response }) => of(setDropDownList([response])))
        )
    })
);

export const requestConsultaEpic = (action$, state$) => action$.pipe(
    ofType(SET_REQUEST_CONSULTA),
    mergeMap((action) => {
        const token = getAccessToken(state$.value);
        return ajax.post(`${process.env.REACT_APP_URL_API}/consulta`, action.payload, { Authorization: `Bearer ${token}` }).pipe(
            map(({ response }) => {
                return consultaSuccessResponse(response);
            }),
            catchError((error) => {
                return of(consultaErrorResponse(error));
            })
        )
    })
);