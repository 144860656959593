import React, { useEffect } from "react";
// Styles
import styles from "../styles/views/admin_registros.module.css";
// Images
import logoFenalco from "../img/logos/logo_fenalco.svg";
import headerHome from "../img/banners/header_home.png";
import back from "../img/icons/atras.svg";
import finI from "../img/icons/finI.svg";
import finD from "../img/icons/finD.svg";
import ante from "../img/icons/ante.svg";
import sig from "../img/icons/sig.svg";
// Redux
import { getAccessToken } from "../redux/modules/steps";
import { useDispatch, useSelector } from "react-redux";
import {
  startBackData,
  startToInitData,
  startGetDataWithDates,
  startToEndData,
  getDataHome,
  startGetNextData,
  setInitDate,
  setFinishDate,
  setNit,
  goBack,
  startGetDataPaginated,
  resetData,
} from "../redux/modules/data";
// Components
import { Loader } from "./LoaderComponent";
import { Link } from "react-router-dom";

export const AllItems = ({ history }) => {
  const dispatch = useDispatch();
  const token = useSelector(getAccessToken);
  useEffect(() => {
    dispatch(startGetDataPaginated());
    return () => dispatch(resetData());
  }, [dispatch]);

  const { response_all, total, loading, desde, hasta, nit, disable_back, disable_to_init, disable_to_end, disable_next, init_date, finish_date } = useSelector(getDataHome);

  const handleExportOnClick = () => {
    if (init_date && finish_date && nit)
      window.open(`${process.env.REACT_APP_URL_API}/downloadAd?nit=${nit}&init_date=${init_date}&finish_date=${finish_date}&authorization=Bearer ${token}`, "_blank");

    if (!init_date && !finish_date && nit) window.open(`${process.env.REACT_APP_URL_API}/downloadAd?nit=${nit}&authorization=Bearer ${token}`, "_blank");

    if (init_date && finish_date && !nit) window.open(`${process.env.REACT_APP_URL_API}/downloadAd?init_date=${init_date}&finish_date=${finish_date}&authorization=Bearer ${token}`, "_blank");

    if (!init_date && !finish_date && !nit) window.open(`${process.env.REACT_APP_URL_API}/downloadAd?authorization=Bearer ${token}`, "_blank");
  };

  const handleInitOnChange = ({ target }) => dispatch(setInitDate(target.value));

  const handleFinishOnChange = ({ target }) => dispatch(setFinishDate(target.value));

  const handleInputOnChange = ({ target }) => dispatch(setNit(target.value));

  const handleInitOnClick = () => dispatch(startToInitData());

  const handleBackOnClick = () => dispatch(startBackData());

  const handleInputOnBlur = () => dispatch(startGetDataWithDates());

  const handleInputFinishDateOnBlur = () => {
    if (init_date && finish_date) {
      dispatch(startGetDataWithDates());
    }
  };

  const handleNextOnClick = () => dispatch(startGetNextData(desde + 10));

  const handleFinishOnClick = () => dispatch(startToEndData(total - (total % 10)));

  const handleGoBackClick = () => {
    dispatch(goBack());
    history.goBack();
  };

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.header_back}>
        <img src={headerHome} alt="" />
      </div>
      <div className="logo">
        <img src={logoFenalco} alt="" />
      </div>
      <div className={styles.data_records}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.header_back}>
              <button onClick={handleGoBackClick} className={styles.back} type="">
                <img src={back} alt="" />
              </button>
              <h4>Historial de autorizaciones </h4>
            </div>
            <div className={styles.filter}>
              <h5>
                Filtrar
                <br />
                por
              </h5>
              <input value={init_date} className={styles.date} onChange={handleInitOnChange} type="date" placeholder="Seleccione fecha de inicio" />
              <input value={finish_date} className={styles.date} onBlur={handleInputFinishDateOnBlur} onChange={handleFinishOnChange} type="date" placeholder="Seleccione fecha fin" />
              <div onChange={handleInputOnChange} onBlur={handleInputOnBlur} className={`input ${styles.input}`}>
                <i className="fas fa-id-card"></i>
                <input value={nit} type="text" placeholder="Nit a consultar" />
              </div>
              <button onClick={handleExportOnClick} className="button_primary">
                Exportar
              </button>
            </div>
            <table>
              <thead>
                <tr className={styles.title_table}>
                  <th>Tipo de consulta</th>
                  <th>Usuario</th>
                  <th>Nombre Empresa</th>
                  <th>Fecha de consulta</th>
                  <th>Fecha de revisión</th>
                  <th>Estado</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {response_all.slice(desde, hasta).map(({ tipo_consulta, fecha, id, usuario, en_proceso, fecha_revision, estado, path_pdf, nombre_empresa }, i) => (
                  <tr key={i}>
                    <td>{tipo_consulta}</td>
                    <td>{usuario}</td>
                    <td>{nombre_empresa}</td>
                    <td>{fecha}</td>
                    <td>{fecha_revision ? fecha_revision : "-"}</td>
                    <td>{estado}</td>
                    <td>
                      {!en_proceso && estado !== "Rechazado" && (
                        <a target="_blank" rel="noreferrer" href={`${path_pdf}&authorization=Bearer ${token}`}>
                          Ver
                        </a>
                      )}
                      {en_proceso && <Link to={`/adminRevision/${id}`}>Ver</Link>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.pager}>
              {!disable_to_init && (
                <button onClick={handleInitOnClick}>
                  <img src={finI} alt="" />
                </button>
              )}
              {!disable_back && (
                <button onClick={handleBackOnClick}>
                  <img src={ante} alt="" />
                </button>
              )}
              <p>
                <b>
                  {desde} a {hasta} :
                </b>
                <i> de {total}</i>
              </p>
              {!disable_next && (
                <button onClick={handleNextOnClick}>
                  <img src={sig} alt="" />
                </button>
              )}
              {!disable_to_end && (
                <button onClick={handleFinishOnClick}>
                  <img src={finD} alt="" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
