import React, { useState } from "react";
// Styles
import styles from "../styles/views/consulta.module.css";
// Redux
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { 
    backStepThreeJuridica, 
    backStepTwoNatural, 
    getSteps, resetErrorSteps, 
    resetResponse, 
    setFileFirma, 
    setRequestConsulta } from "../redux/modules/steps";
// Hooks
import { useForm } from "react-hook-form";
// Components
import { DropDownList } from "./DropDownList";
import { Loader } from "./LoaderComponent";
// Router
import { useHistory, useParams } from "react-router";
// Helpers
import { getError, getSuccess } from "../helpers/messagesSwal";

export const StepThree = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { type: tipo } = useParams();
  const [fileLoaded, setFileLoaded] = useState(false);
  const { file_camara_comercio, file_cedula, file_firma, response_consulta, error, loading } = useSelector(getSteps);
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  const onSubmit = ({ documento, nombre, primer_apellido, segundo_apellido, type, accept_terms }) => {
    if (!fileLoaded) {
      getError("Debe cargar el adjunto Solicitado");
      return;
    }

    if (accept_terms) {
      const formData = new FormData();
      if (tipo === "Juridica") formData.append("file", file_camara_comercio, file_camara_comercio.name);
      formData.append("file2", file_cedula, file_cedula.name);
      formData.append("file3", file_firma, file_firma.name);
      formData.append("tipo_consulta", tipo);
      formData.append("documento_consultado", documento);
      formData.append("nombre", nombre);
      formData.append("primer_apellido", primer_apellido);
      formData.append("segundo_apellido", segundo_apellido);
      formData.append("valor", type);
      dispatch(setRequestConsulta(formData));
    } else {
      getError("Debe aceptar los términos y condiciones");
      return;
    }
  };

  const handleBackOnClick = () => (tipo === "Natural" ? dispatch(backStepTwoNatural()) : dispatch(backStepThreeJuridica()));

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: "application/pdf, image/png, image/jpg",
    onDrop: (acceptedFiles) => {
      setFileLoaded(true);
      dispatch(setFileFirma(acceptedFiles[0]));
    },
    onDropRejected: () => {
      setFileLoaded(false);
      getError("El archivo debe tener uno de las formatos validos(png, jpg, pdf)");
    },
  });

  if (response_consulta) {
    getSuccess(response_consulta?.response).then(() => {
      history.push("/");
      dispatch(resetResponse());
    });
  }
  if (error) {
    getError("No se logro hacer la solicitud de consulta");
    dispatch(resetErrorSteps());
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.step_three}>
        <div className={styles.terms}>
          <h4>AUTORIZACION PARA LA CONSULTA, REPORTE Y PROCESAMIENTO DE DATOS COMERCIALES Y FINANCIEROS EN FENALCO ANTIOQUIA.</h4>
          <p style={{ textAlign: "center" }}>
            Autorizamos expresamente la FEDERACIÓN NACIONAL DE COMERCIANTES – FENALCO ANTIOQUIA para que la información suministrada en este documento, que tiene carácter personal y comercial, previo
            a la relación contractual y de manera irrevocable, escrita, expresa, concreta, suficiente, voluntaria e informada, con la finalidad que la información comercial, crediticia, financiera y
            de servicios de la cual soy titular, referida al nacimiento, ejecución y extinción de obligaciones dinerarias (independientemente de la naturaleza del contrato que les dé origen), a mi
            comportamiento e historial crediticio, incluida la información positiva y negativa de mis hábitos de pago, y aquella que se refiera a la información personal necesaria para el estudio,
            análisis y eventual otorgamiento de un crédito o celebración de un contrato, sea en general administrada y en especial: capturada, tratada, procesada, operada, verificada, transmitida,
            transferida, usada o puesta en circulación y consultada por terceras personas autorizadas expresamente por la ley 1266 de 2008, incluidos los Usuarios de la Información. Con estos mismos
            alcances, atributos y finalidad autorizo expresamente para que tal información sea concernida y reportada en la Base de Datos PROCRÉDITO operada por FENALCO ANTIOQUIA. De la misma manera
            autorizo a FENALCO, como Operador de la Base de Datos PROCRÉDITO que tiene una finalidad estrictamente comercial, financiera, crediticia y de servicios, para que procese, opere y
            administre la información de la cual soy titular, y para que la misma sea transferida y transmitida a Usuarios, lo mismo que a otros operadores nacionales o extranjeros que tengan la misma
            finalidad o una finalidad que comprenda la que tiene PROCRÉDITO. Certifico que los datos personales suministrados por mí, son veraces, completos, exactos, actualizados, reales y
            comprobables. Por tanto, cualquier error en la información suministrada será de mi única y exclusiva responsabilidad, lo que exonera a FENALCO de su responsabilidad ante las autoridades
            judiciales y/o administrativas. Declaro que he leído y comprendido a cabalidad el contenido de la presente Autorización, y acepto la finalidad en ella descrita y las consecuencias que se
            derivan de ella.
          </p>
        </div>
        <div className={styles.accept_terms}>
          <p>Al estar de acuerdo con lo leído:</p>
          <div className={styles.terms_data}>
            <div className={styles.name}>
              {tipo !== "Juridica" && (
                <>
                  <h4>Yo:</h4>
                  <div className={styles.text_name}>
                    <div className={styles.input}>
                      <i className="fas fa-user"></i>
                      <input type="text" className={errors.nombre && "input_error"} name="nombre" ref={register({ required: true })} placeholder="Nombre" />
                    </div>
                    <div className={styles.last_name}>
                      <div className={styles.input}>
                        <i className="fas fa-user"></i>
                        <input type="text" className={errors.primer_apellido && "input_error"} name="primer_apellido" ref={register({ required: true })} placeholder="Primer Apellido" />
                      </div>
                      <div className={styles.input}>
                        <i className="fas fa-user"></i>
                        <input type="text" className={errors.segundo_apellido && "input_error"} name="segundo_apellido" ref={register({ required: true })} placeholder="Segundo Apellido" />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              <h4>Con Documento:</h4>
              <div className={styles.id_elements}>
                <DropDownList clase={styles.select} register={register} errors={errors} />
                <div className={styles.input}>
                  <i className="fas fa-id-card"></i>
                  <input type="number" className={errors.documento && "input_error"} name="documento" ref={register({ required: true })} placeholder="Número de documento" />
                </div>
              </div>
            </div>

            <section className={`${styles.signature_acept} ${styles.hov}`} id={styles.zonadestino}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {acceptedFiles.length === 1 ? <p>{acceptedFiles[0].name}</p> : <p>Adjunta tu firma digital</p>}
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className={styles.accept_terms_button}>
        <div className={styles.check}>
          <input className="pointer" name="accept_terms" value={undefined} ref={register} type="checkbox" />
          <label>Acepto los</label>
          <a href="/">Términos y condiciones</a>
        </div>

        <div className={`${styles.buttons_terms} mb-2`}>
          <button onClick={handleBackOnClick} className="button_secondary">
            Volver
          </button>
          <button type="submit" className="button_primary">
            Continuar
          </button>
        </div>
      </div>
    </form>
  );
};
