import React, { useEffect } from "react";
// Styles
import styles from "../../styles/views/consulta.module.css";
// Images
import logoFenalco from "../../img/logos/logo_fenalco.svg";
import headerHome from "../../img/banners/header_home.png";
import back from "../../img/icons/atras.svg";
// Components
import { Juridica } from "./ConsultaJuridica";
import { Natural } from "./ConsultaNatural";
// Redux
import { useDispatch } from "react-redux";
import { getDropDownList, resetResponse } from "../../redux/modules/steps";
// Router
import { useParams } from "react-router";

export const Consulta = ({ history }) => {
  const { type } = useParams();
  const dispatch = useDispatch();
  useEffect(() => dispatch(getDropDownList()), [dispatch]);

  const handleOnClick = () => {
    dispatch(resetResponse());
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header_back}>
        <img src={headerHome} alt="" />
      </div>
      <div className="logo">
        <img src={logoFenalco} alt="" />
      </div>
      <div className={styles.query_data}>
        <div className={styles.header_back}>
          <button onClick={handleOnClick} className={styles.back} type="">
            <img src={back} alt="" />
          </button>
          <h4 className="mb-3small">Consulta {type === "Juridica" ? "jurídica" : "natural"}</h4>
        </div>
        {type === "Juridica" ? <Juridica /> : <Natural />}
      </div>
    </div>
  );
};
