import React from "react";
// Styles
import styles from "../styles/views/login.module.css";

export const Contact = () => {
  return (
    <div className={styles.contact}>
      <ul>
        <li className={styles.text}>Contáctenos</li>
        <li>
          <a href="https://fenalcoantioquia.com/contacto/" target="_blank" rel="noreferrer">
            <i className="far fa-comment-dots"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/fenalcoantioquia/" target="_blank" rel="noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/Fenalco_Ant" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/fenalcoantioquia/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/fenalco-antioquia/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </li>
        <li>
          <a
            href="https://api.whatsapp.com/send?phone=573135069391&text=%C2%A1Hola!%20Busco%20comunicaci%C3%B3n%20desde%20el%20portal%20FENALCO&source=&data=&app_absent="
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};
