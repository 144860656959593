import React, { useEffect } from "react";
// Styles
import styles from "../../styles/views/home.module.css";
// Images
import logoFenalco from "../../img/logos/logo_fenalco.svg";
import headerHome from "../../img/banners/header_home.png";
// Components
import { CardAdmin } from "../../components/CardComponent";
import { Loader } from "../../components/LoaderComponent";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDataHome, startGetDataAdmin } from "../../redux/modules/data";
import { startLogOut } from "../../redux/modules/auth";
// Router
import { Link } from "react-router-dom";

export const HomeAdmin = () => {
  const dispatch = useDispatch();
  const { response, loading } = useSelector(getDataHome);
  useEffect(() => dispatch(startGetDataAdmin()), [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header_back}>
        <img src={headerHome} alt="" />
      </div>
      <div className="logo">
        <img src={logoFenalco} alt="" />
      </div>
      <div className={styles.button_query_one}>
        <button onClick={() => dispatch(startGetDataAdmin())} className={`button_secondary ${styles.button_secondary}`}>
          <i className="fas fa-sync-alt"></i>
        </button>
        <button onClick={() => dispatch(startLogOut())} className={`button_secondary ${styles.button_secondary}`}>
          <i className="fas fa-sign-out-alt"></i>
        </button>
      </div>
      <div className={styles.data_home}>
        {response.length > 0 ? (
          <>
            <h4>Administrador</h4>

            <h5>Pendientes por autorizar</h5>
            {response.map((value) => {
              if (value.en_proceso) {
                return <CardAdmin key={value.id} {...value} />;
              }
              return null;
            })}

            <div className={styles.title_links}>
              <h5>Ultimas autorizaciones realizadas</h5>
              <Link to="/allAdminItems">Ver todas las autorizaciones</Link>
            </div>

            {response.map((value) => {
              if (!value.en_proceso) {
                return <CardAdmin key={value.id} {...value} />;
              }
              return null;
            })}
          </>
        ) : (
          <h2 className="center-text">No hay consultas disponibles</h2>
        )}
      </div>
    </div>
  );
};
