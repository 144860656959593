import React from 'react';
// Router
import { Redirect, Route } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
import { getAuthProps } from '../redux/modules/auth';

const PublicRoute = ({ component: Component, ...rest }) => {

    const { isAuthenticated, admin } = useSelector(getAuthProps);

    return (
        <Route {...rest} render={props => {

            if (isAuthenticated && admin === '0') {
                return <Redirect to="/" />
            }

            if (isAuthenticated && admin === '1') {
                return <Redirect to="/admin" />
            }

            return <Component {...props} />
        }} />
    );
};

export default PublicRoute;