import React from "react";
// Redux
import { useSelector } from "react-redux";

export const DropDownList = ({ register, errors, clase, valor, handleOnChange }) => {
  const { dropdown_data } = useSelector((state) => state.steps);

  return (
    <div className={clase}>
      <i className="fas fa-id-card"></i>
      <select className={errors.type && "input_error"} ref={register({ validate: (value) => parseInt(value) > 0 })} defaultValue={valor} onChange={handleOnChange} name="type">
        {dropdown_data.map(({ valor, nombre }, i) => (
          <option key={i} value={valor}>
            {nombre}
          </option>
        ))}
      </select>
    </div>
  );
};
