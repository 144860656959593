import React, { useEffect } from "react";
// Styles
import styles from "../../styles/views/admin_revision.module.css";
// Images
import logoFenalco from "../../img/logos/logo_fenalco.svg";
import headerHome from "../../img/banners/header_home.png";
import back from "../../img/icons/atras.svg";
// Router
import { useParams } from "react-router";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDataUserById, resetInfoUser } from "../../redux/modules/data";
import { startSubmitReject, getDataAdmin, resetState, setTable, startSubmitApprove } from "../../redux/modules/admin";
// Components
import { Loader } from "../../components/LoaderComponent";
import { getError, getReject, getSuccess } from "../../helpers/messagesSwal";
import { Table } from "../../components/TableComponent";

export const CifinAdmin = ({ history }) => {
  const dispatch = useDispatch();
  const { current_user, loading, showModal, error, isApproved, isRejected, table, loading_admin } = useSelector(getDataAdmin);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getDataUserById(id));
  }, [id, dispatch]);

  if (error) {
    getError(error.response ? error.response.error : error.message);
  }

  if (isRejected) {
    getSuccess(isRejected).then(() => {
      dispatch(resetState());
      history.push("/admin");
    });
  }

  if (isApproved) {
    getSuccess(isApproved).then(() => {
      dispatch(resetState());
      history.push("/admin");
    });
  }

  if (showModal) {
    getReject("Escriba el motivo por el cual rechaza la consulta").then(({ isConfirmed, mensaje }) => isConfirmed && dispatch(startSubmitReject({ id, mensaje })));
  }

  const handleAprobarOnClick = () => {
    dispatch(
      startSubmitApprove({
        id,
        apellido: current_user?.primer_apellido,
        documento: current_user?.documento_consultado,
        tipo_documento: current_user?.tipo_documento,
        user: current_user?.usuario,
        nit: current_user?.nit,
        table,
      })
    );
  };

  const handleBackOnClick = () => {
    dispatch(resetInfoUser());
    history.goBack();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setTable({ name: name, table: { dict_veces: value } }));
  };

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.header_back}>
        <img src={headerHome} alt="" />
      </div>
      <div className="logo">
        <img src={logoFenalco} alt="" />
      </div>
      <div className={styles.data_review}>
        {loading || loading_admin ? (
          <Loader />
        ) : (
          <>
            <div className={styles.header_back}>
              <button onClick={handleBackOnClick} className={styles.back} type="">
                <img src={back} alt="" />
              </button>
              <h4>Datos CIFIN</h4>
            </div>
            <div className={styles.data_inf}>
              <div className={`card ${styles.card}`}>
                <div className="information">
                  <span>Tipo de consulta</span>
                  <p>{current_user?.tipo_consulta}</p>
                </div>
                <div className="information">
                  <span>Usuario</span>
                  <p>{current_user?.usuario}</p>
                </div>
                <div className="information">
                  <span>Fecha de consulta</span>
                  <p>{current_user?.fecha}</p>
                </div>
                <div className="information">
                  <span>Hora de consulta</span>
                  <p>{current_user?.hora}</p>
                </div>
              </div>
              <div className={styles.button_review}>
                <button onClick={handleAprobarOnClick} className={`button_primary`}>
                  Enviar
                </button>
              </div>
            </div>
            <div className={styles.cards_ver}>
              <div className={styles.card_table}>
                <Table title="Obligaciones como deudor" idName="response_principal" />
              </div>
              <div className={styles.card_table}>
                <Table title="Obligaciones como codeudor" idName="response_diferente_principal" />
              </div>
            </div>
            <div className={styles.card_table}>
              <div className={styles.text_img}>
                <p>Huella de consulta últimos 6 meses</p>
                <input type="number" name="dict_veces" className={styles.inputCifin} onChange={handleChange} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
