import { createSelector } from 'reselect';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { resetError, setError } from './errors';

// TYPES
const START_GET_DATA = '[DATA]/START_GET_DATA';
const START_GET_NEXT_DATA = '[DATA]/START_GET_NEXT_DATA';
const START_GET_DATA_WITH_DATES = '[DATA]/START_GET_DATA_WITH_DATES';
const START_TO_END_DATA = '[DATA]/START_TO_END_DATA';
const START_GET_DATA_PAGINATED = '[DATA]/START_GET_DATA_PAGINATED';
const START_TO_INIT_DATA = '[DATA]/START_TO_INIT_DATA';
const START_BACK_DATA = '[DATA]/START_BACK_DATA';
const BACK_DATA = '[DATA]/BACK_DATA';
const GET_NEXT_DATA_SUCCESS = '[DATA]/GET_NEXT_DATA_SUCCESS';
const GET_DATA_PAGINATED_SUCCESS = '[DATA]/GET_DATA_PAGINATED_SUCCESS';
const GET_NEXT_DATA_ERROR = '[DATA]/GET_NEXT_DATA_ERROR';
const GET_TO_END_DATA_SUCCESS = '[DATA]/GET_TO_END_DATA_SUCCESS';
const GET_TO_END_DATA_ERROR = '[DATA]/GET_TO_END_DATA_ERROR';
const START_GET_DATA_ADMIN = '[DATA]/START_GET_DATA_ADMIN';
const START_GET_DATA_USER_BY_ID = '[DATA]/START_GET_DATA_USER_BY_ID';
const GET_DATA_USER_BY_ID_SUCCESS = '[DATA]/GET_DATA_USER_BY_ID_SUCCESS';
const GET_DATA_SUCCESS = '[DATA]/GET_DATA_SUCCESS';
const GET_DATA_USER_BY_ID_ERROR = '[DATA]/GET_DATA_USER_BY_ID_ERROR';
const FINISH_LOADING = '[DATA]/FINISH_LOADING';
const RESET_INFO_USER = '[DATA]/RESET_INFO_USER';
const VALIDATE_BACK_FINISH = '[DATA]/VALIDATE_BACK_FINISH';
const VALIDATE_NEXT_FINISH = '[DATA]/VALIDATE_NEXT_FINISH';
const SET_INIT_DATE = '[DATA]/SET_INIT_DATE';
const SET_FINISH_DATE = '[DATA]/SET_FINISH_DATE';
const SET_NIT = '[DATA]/SET_NIT';
const GO_BACK = '[DATA]/GO_BACK';
const RESET_DATA = '[DATA]/RESET_DATA';

const initialState = {
    loading: true,
    current_user: null,
    init_date: null,
    finish_date: null,
    documento: null,
    response: [],
    response_all: [],
    disable_back: true,
    disable_next: false,
    disable_to_end: false,
    disable_to_init: true,
}

// REDUCER
export default function dataReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_DATA:
            return initialState;
        case START_GET_NEXT_DATA:
            return {
                ...state,
                loading: true
            };
        case START_GET_DATA_PAGINATED:
            return {
                ...state,
                loading: true
            };
        case START_GET_DATA_WITH_DATES:
            return {
                ...state,
                loading: true
            };
        case START_TO_END_DATA:
            return {
                ...state,
                loading: true
            };
        case START_TO_INIT_DATA:
            return {
                ...state,
                response_all: state.response,
                desde: 0,
                hasta: 10,
                disable_back: true,
                disable_next: false,
                disable_to_init: true,
                disable_to_end: false,
            };
        case GET_DATA_SUCCESS:
            return {
                ...state,
                response: action.payload.response,
                response_all: action.payload.response,
                total: action.payload.total,
                loading: false
            };
        case GET_NEXT_DATA_SUCCESS:
            return {
                ...state,
                desde: state.response_all.length,
                response_all: [...state.response_all, ...action.payload.response],
                hasta: action.payload.response.length === 10 ? state.hasta + 10 : state.total,
                total: action.payload.total,
                disable_back: false,
                disable_next: false,
                disable_to_init: false,
                disable_to_end: false,
                loading: false
            };
        case VALIDATE_NEXT_FINISH:
            return {
                ...state,
                disable_next: state.response_all.length === state.total ? true : false,
                disable_to_end: state.response_all.length === state.total ? true : false,
            };
        case GET_TO_END_DATA_SUCCESS:
            return {
                ...state,
                desde: state.total - state.total % 10,
                response_all: action.payload.response,
                hasta: action.payload.total,
                total: action.payload.total,
                disable_back: false,
                disable_next: true,
                disable_to_init: false,
                disable_to_end: true,
                loading: false
            };
        case BACK_DATA:
            return {
                ...state,
                desde: state.desde - 10,
                hasta: (state.hasta % 10 === 0 && state.desde !== 0) ? state.hasta - 10 : (state.hasta - state.hasta % 10),
                disable_back: false,
                disable_next: false,
                disable_to_end: false,
                disable_to_init: false
            };
        case VALIDATE_BACK_FINISH:
            return {
                ...state,
                is_back_finish: state.desde === 0 ? true : false,
                response_all: state.desde === 0 ? state.response : state.response_all,
                disable_back: state.desde === 0 ? true : false,
                disable_to_init: state.desde === 0 ? true : false
            };
        case GET_NEXT_DATA_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case FINISH_LOADING:
            return {
                ...state,
                loading: false
            };
        case RESET_INFO_USER:
            return {
                ...state,
                current_user: null
            };
        case GET_DATA_USER_BY_ID_SUCCESS:
            return {
                ...state,
                current_user: action.payload,
                loading: false
            };
        case GET_DATA_USER_BY_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case START_GET_DATA_USER_BY_ID:
            return {
                ...state,
                loading: true
            };
        case SET_INIT_DATE:
            return {
                ...state,
                init_date: action.payload
            };
        case SET_FINISH_DATE:
            return {
                ...state,
                finish_date: action.payload
            };
        case SET_NIT:
            return {
                ...state,
                nit: action.payload
            };
        case GO_BACK:
            return { ...initialState, loading: false, response: state.response };
        case GET_DATA_PAGINATED_SUCCESS:
            return {
                ...state,
                desde: action.payload.response.length - action.payload.response.length,
                hasta: action.payload.response.length,
                total: action.payload.total,
                loading: false,
                response_all: action.payload.response
            };
        default:
            return state;
    }
}

// SELECTORS
export const getData = state => state.data

export const getDataHome = createSelector(
    getData,
    (data) => ({ ...data })
);

// ACTIONS
export const startGetData = () => ({ type: START_GET_DATA });
export const startGetDataPaginated = () => ({ type: START_GET_DATA_PAGINATED });
export const startGetDataAdmin = () => ({ type: START_GET_DATA_ADMIN });
export const startToEndData = () => ({ type: START_TO_END_DATA });
export const startToInitData = () => ({ type: START_TO_INIT_DATA });
export const startGetDataWithDates = () => ({ type: START_GET_DATA_WITH_DATES });
export const resetInfoUser = () => ({ type: RESET_INFO_USER });
export const startBackData = () => ({ type: START_BACK_DATA });
export const startGetNextData = (payload) => ({ type: START_GET_NEXT_DATA, payload });
export const getDataUserById = payload => ({ type: START_GET_DATA_USER_BY_ID, payload });
export const setInitDate = payload => ({ type: SET_INIT_DATE, payload });
export const setNit = payload => ({ type: SET_NIT, payload });
export const setFinishDate = payload => ({ type: SET_FINISH_DATE, payload });
export const goBack = () => ({ type: GO_BACK });
export const resetData = () => ({ type: RESET_DATA });
const getDataUserByIdSuccess = payload => ({ type: GET_DATA_USER_BY_ID_SUCCESS, payload });
const getDataUserByIdError = payload => ({ type: GET_DATA_USER_BY_ID_ERROR, payload });
const getDataSuccess = payload => ({ type: GET_DATA_SUCCESS, payload });
const finishLoading = payload => ({ type: FINISH_LOADING, payload });
const getNextDataSuccess = payload => ({ type: GET_NEXT_DATA_SUCCESS, payload });
const getNextDataError = payload => ({ type: GET_NEXT_DATA_ERROR, payload });
const getToEndDataSuccess = payload => ({ type: GET_TO_END_DATA_SUCCESS, payload });
const getToEndDataError = payload => ({ type: GET_TO_END_DATA_ERROR, payload });
const getDataPaginatedSuccess = payload => ({ type: GET_DATA_PAGINATED_SUCCESS, payload });


const validateBackFinish = () => ({ type: VALIDATE_BACK_FINISH });
const validateNextFinish = () => ({ type: VALIDATE_NEXT_FINISH });
const backData = () => ({ type: BACK_DATA });


//EPICS
export const getDataEpic = (action$, state$) => action$.pipe(
    ofType(START_GET_DATA),
    mergeMap(() =>
        ajax.getJSON(`${process.env.REACT_APP_URL_API}/home`,
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                mergeMap(response => of(resetError(), getDataSuccess(response))),
                catchError((error) => of(
                    setError(`No se logro obtener los datos \n ${error}`),
                    finishLoading()))
            ))
);

export const getDataWithDatesEpic = (action$, state$) => action$.pipe(
    ofType(START_GET_DATA_WITH_DATES),
    mergeMap(() =>
        ajax.getJSON(`${process.env.REACT_APP_URL_API}/Fenalcoad?init_date=${state$.value.data.init_date}&finish_date=${state$.value.data.finish_date}&nit=${state$.value.data.nit}`,
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                mergeMap(response => of(resetError(), getDataSuccess(response), validateNextFinish())),
                catchError((error) => of(
                    setError(`No se logro obtener los datos \n ${error}`),
                    finishLoading()))
            ))
);

export const getNextDataEpic = (action$, state$) => action$.pipe(
    ofType(START_GET_NEXT_DATA),
    mergeMap((action) =>
        ajax.getJSON(`${process.env.REACT_APP_URL_API}/Fenalcoad?start_pag=${action.payload}&init_date=${state$.value.data.init_date}&finish_date=${state$.value.data.finish_date}`,
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                mergeMap(response => of(getNextDataSuccess(response), validateNextFinish())),
                catchError((error) => of(getNextDataError(error)))
            ))
);

export const getDataPaginatedEpic = (action$, state$) => action$.pipe(
    ofType(START_GET_DATA_PAGINATED),
    mergeMap(() =>
        ajax.getJSON(`${process.env.REACT_APP_URL_API}/Fenalcoad?init_date=${state$.value.data.init_date}&finish_date=${state$.value.data.finish_date}`,
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                mergeMap(response => of(getDataPaginatedSuccess(response), validateNextFinish())),
                catchError((error) => of(getNextDataError(error)))
            ))
);

export const getToEndDataEpic = (action$, state$) => action$.pipe(
    ofType(START_TO_END_DATA),
    mergeMap((action) =>
        ajax.getJSON(`${process.env.REACT_APP_URL_API}/Fenalcoad?start_pag=0&end_pag=${state$.value.data.total}&init_date=${state$.value.data.init_date}&finish_date=${state$.value.data.finish_date}`,
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                map(response => getToEndDataSuccess({ ...response, desde: action.payload })),
                catchError((error) => of(getToEndDataError(error)))
            ))
);

export const startBackDataEpic = (action$) => action$.pipe(
    ofType(START_BACK_DATA),
    mergeMap(() => (of(backData(), validateBackFinish())))
);

export const getAdminDataEpic = (action$, state$) => action$.pipe(
    ofType(START_GET_DATA_ADMIN),
    mergeMap(() =>
        ajax.getJSON(`${process.env.REACT_APP_URL_API}/Fenalcoad`,
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                mergeMap(response => of(resetError(), getDataSuccess(response))),
                catchError((error) => of(
                    setError(`No se logro obtener los datos de la consulta \n ${error}`),
                    finishLoading()))
            ))
);

export const getDataUserByIdEpic = (action$, state$) => action$.pipe(
    ofType(START_GET_DATA_USER_BY_ID),
    mergeMap((action) =>
        ajax.post(`${process.env.REACT_APP_URL_API}/filteruserAd`,
            JSON.stringify({ id: action.payload }),
            { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
                map(({ response }) => getDataUserByIdSuccess(response)),
                catchError(({ message, status, response }) => of(getDataUserByIdError({ error: message, status, response })))
            )
    )
);