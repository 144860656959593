// Libraries
import Swal from 'sweetalert2';
import styles from '../styles/views/modal.module.css';

export const getError = async (error, count = 0) => {
    const response = await Swal.fire({
        showCloseButton: count <= 1 ? true : false,
        allowOutsideClick: false,
        showConfirmButton: count > 1 ? true : false,
        icon: 'error',
        width: '60%',
        customClass: {
            content: styles.title_modal,
            confirmButton: `${styles.modal_button} ${styles.modal_button_primary}`,
        },
        html: `<p>${error}</p>`
    })
    return response;
}

export const getSuccess = async (message) => {
    const response = await Swal.fire({
        allowOutsideClick: false,
        width: '60%',
        icon: 'success',
        customClass: {
            closeButton: styles.x_modal,
            confirmButton: `${styles.modal_button} ${styles.modal_button_primary}`,
            content: styles.title_modal
        },
        html: `<p>${message}</p>`
    })

    return response;
}

export const getReject = async (message) => {
    const { value, isConfirmed } = await Swal.fire({
        showCloseButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        width: '60%',
        input: 'textarea',
        inputValidator: (value) => {
            if (!value) {
                return 'Debe ingresar el motivo por el cual rechaza la consulta'
            }
        },
        customClass: {
            closeButton: styles.x_modal,
            input: styles.modal_textarea,
            confirmButton: `${styles.modal_button} ${styles.modal_button_primary}`,
            cancelButton: `${styles.modal_button} ${styles.modal_button_secondary}`,
            actions: styles.buttons_modal,
            content: styles.title_modal
        },
        html: `<p>${message}</p>`
    })

    return { mensaje: value, isConfirmed };
}

export const getMessageReject = async (nit, fecha, hora, estado, message) => {
    Swal.fire({
        showCloseButton: true,
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        width: '60%',
        customClass: {
            htmlContainer: styles.modal,
            closeButton: styles.x_modal,
        },
        html: `
                <div class="card ${styles.card_modal}">
                    <div class="information information_modal">
                        <span>Consulta con NIT</span>
                        <p>${nit}</p>
                    </div>
                    <div class="information information_modal">
                        <span>Fecha de consulta</span>
                        <p>${fecha}</p>
                    </div>
                    <div class="information information_modal">
                        <span>Hora de consulta</span>
                        <p>${hora}</p>
                    </div>
                    <div class="information information_modal">
                        <span>Estado</span>
                        <p>${estado}</p>
                    </div>
                </div>
                <div class="card ${styles.card_modal}">
                    <div class="information">
                        <span class="center-text">Tu consulta fue rechazada por</span>
                        <p class="center-text">${message}</p>
                    </div>
                </div>`
    })
}