import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from "./root";

const epicMiddleware = createEpicMiddleware();

const composeEnhancers =
    (process.env.REACT_APP_NODE_ENV !== 'prod' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;


export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(epicMiddleware)
    )
);

epicMiddleware.run(rootEpic);