import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import authReducer, { fetchLoginEpic, logOutEpic } from './modules/auth';
import dataReducer, { getAdminDataEpic, getDataEpic, getDataPaginatedEpic, getDataUserByIdEpic, getDataWithDatesEpic, getNextDataEpic, getToEndDataEpic, startBackDataEpic } from './modules/data';
import stepsReducer, { getDropDownListEpic, requestConsultaEpic } from './modules/steps';
import adminReducer, { approveEpic, rejectEpic } from './modules/admin';
import errorReducer from './modules/errors';


export const rootEpic = combineEpics(
    fetchLoginEpic,
    getDropDownListEpic,
    getDataEpic,
    getAdminDataEpic,
    getDataUserByIdEpic,
    rejectEpic,
    approveEpic,
    getNextDataEpic,
    startBackDataEpic,
    getToEndDataEpic,
    getDataWithDatesEpic,
    logOutEpic,
    getDataPaginatedEpic,
    requestConsultaEpic
);

export const rootReducer = combineReducers({
    auth: authReducer,
    steps: stepsReducer,
    data: dataReducer,
    admin: adminReducer,
    errors: errorReducer
});