import React from "react";
// Router
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import AdminRoute from "./routes/AdminRoute";
// views
import { Consulta } from "./views/consulta/ConsultaView";
import { Login } from "./views/login/LoginView";
import { Home } from "./views/home/HomeView";
import { HomeAdmin } from "./views/admin/HomeAdminView";
import { AllItems } from "./components/AllItemsView";
import { RevisionAdmin } from "./views/admin/RevisionAdminView";
import { CifinAdmin } from "./views/admin/CifinAdminView";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getErrorAuth } from "./redux/modules/errors";
import { startLogOut } from "./redux/modules/auth";
// Components
import { Loader } from "./components/LoaderComponent";
// Helpers
import { getError } from "./helpers/messagesSwal";
// Libraries
import { useIdleTimer } from "react-idle-timer";

const App = () => {
  const { error, loading } = useSelector(getErrorAuth);
  const dispatch = useDispatch();
  const handleOnIdle = () => {
    dispatch(startLogOut());
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  if (loading) {
    return <Loader />;
  }

  if (error && !loading) {
    getError(error);
  }

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute component={Login} path="/auth" exact />
        <PrivateRoute component={Home} path="/" exact />
        <PrivateRoute component={Consulta} path="/consulta/:type" exact />
        <AdminRoute component={HomeAdmin} path="/admin" exact />
        <AdminRoute component={RevisionAdmin} path="/adminRevision/:id" exact />
        <AdminRoute component={CifinAdmin} path="/cifinRevision/:id" exact />
        <AdminRoute component={AllItems} path="/allAdminItems" exact />
        <Redirect to="/auth" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
