import { createSelector } from "reselect";
import { ofType } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { getData } from "./data";

// TYPES
const CLICK_REJECT = "[ADMIN]/CLICK_REJECT";
const START_SUBMIT_APPROVE = "[ADMIN]/START_SUBMIT_APPROVE";
const APPROVE_SUCCESS = "[ADMIN]/APPROVE_SUCCESS";
const APPROVE_ERROR = "[ADMIN]/APPROVE_ERROR";
const START_SUBMIT_REJECT = "[ADMIN]/START_SUBMIT_REJECT";
const REJECT_SUCCESS = "[ADMIN]/REJECT_SUCCESS";
const REJECT_ERROR = "[ADMIN]/REJECT_ERROR";
const RESET_STATE = "[ADMIN]/RESET_STATE";
const SET_TABLE = "[ADMIN]/SET_TABLE";
const CLEAR_ERROR = "[ADMIN]/CLEAR_ERROR";

const initialState = {
  isApproved: false,
  isRejected: false,
  showModal: false,
  error: null,
  count: 0,
  response: null,
  loading: false,
  table: {},
};

// REDUCER
export default function adminReducer(state = initialState, action) {
  switch (action.type) {
    case START_SUBMIT_REJECT:
      return {
        ...state,
        loading: true,
        showModal: false,
      };
    case START_SUBMIT_APPROVE:
      return {
        ...state,
        loading: true,
        showModal: false,
      };
    case CLICK_REJECT:
      return {
        ...state,
        showModal: true,
      };
    case REJECT_SUCCESS:
      return {
        ...state,
        showModal: false,
        loading: false,
        isRejected: action.payload.response,
      };
    case REJECT_ERROR:
      return {
        ...state,
        showModal: false,
        loading: false,
        error: action.payload,
        count: action.payload.response.in_cifin ? (state.count += 1) : state.count,
      };
    case APPROVE_SUCCESS:
      return {
        ...state,
        showModal: false,
        loading: false,
        isApproved: action.payload.response,
      };
    case APPROVE_ERROR:
      return {
        ...state,
        showModal: false,
        loading: false,
        error: action.payload,
      };
    case SET_TABLE:
      return {
        ...state,
        table: { ...state.table, [action.payload.name]: action.payload.table },
      };
    case RESET_STATE:
      return initialState;
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

// SELECTORS
export const getAdmin = (state) => state.admin;
export const getAuth = (state) => state.auth;

export const getDataAdmin = createSelector([getData, getAdmin, getAuth], (data, admin, { access_token }) => ({ loading_admin: admin.loading, ...admin, token: access_token, ...data }));

// ACTIONS
export const clickInReject = () => ({ type: CLICK_REJECT });
export const resetState = () => ({ type: RESET_STATE });
export const clearError = () => ({ type: CLEAR_ERROR });
export const startSubmitReject = (payload) => ({ type: START_SUBMIT_REJECT, payload });
export const startSubmitApprove = (payload) => ({ type: START_SUBMIT_APPROVE, payload });
export const setTable = (payload) => ({ type: SET_TABLE, payload });
const rejectSuccess = (payload) => ({ type: REJECT_SUCCESS, payload });
const rejectError = (payload) => ({ type: REJECT_ERROR, payload });
const approveSuccess = (payload) => ({ type: REJECT_SUCCESS, payload });
const approveError = (payload) => ({ type: REJECT_ERROR, payload });

//EPICS
export const rejectEpic = (action$, state$) =>
  action$.pipe(
    ofType(START_SUBMIT_REJECT),
    mergeMap((action) =>
      ajax.post(`${process.env.REACT_APP_URL_API}/denyAd`, JSON.stringify(action.payload), { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
        map(({ response, status }) => (status === 200 ? rejectSuccess(response) : rejectError(response?.error))),
        catchError((error) => of(rejectError(error)))
      )
    )
  );

export const approveEpic = (action$, state$) =>
  action$.pipe(
    ofType(START_SUBMIT_APPROVE),
    mergeMap((action) =>
      ajax.post(`${process.env.REACT_APP_URL_API}/approve`, JSON.stringify(action.payload), { Authorization: `Bearer ${state$.value.auth.access_token}` }).pipe(
        map(({ response, status }) => (status === 200 ? approveSuccess(response) : approveError(response?.error))),
        catchError((error) => of(approveError(error)))
      )
    )
  );
