import React from 'react';
// Router
import { Redirect, Route } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
import { getAuthProps } from '../redux/modules/auth';


const AdminRoute = ({ component: Component, ...rest }) => {

    const { isAuthenticated, admin } = useSelector(getAuthProps);

    return (
        <Route {...rest} render={props => (
            isAuthenticated && admin === '1' ?
                <Component {...props} />
                : <Redirect to="/auth" />
        )} />
    );
};

export default AdminRoute;