import React from "react";
// Styles
import styles from "../../styles/views/consulta.module.css";
// Components
import { StepThree } from "../../components/StepThreeComponent";
import { StepTwo } from "../../components/StepTwoComponent";
// Redux
import { useSelector } from "react-redux";
import { getSteps } from "../../redux/modules/steps";


export const Natural = () => {
  const { current_step_natural, loading } = useSelector(getSteps);

  return (
    <>
      {!loading && (
        <div className={styles.steps}>
          <div className={current_step_natural === 1 ? styles.active : styles.off}>
            <p>
              <b>1 </b>Fotocopia cédula
            </p>
          </div>
          <div className={current_step_natural === 2 ? styles.active : styles.off}>
            <p>
              <b>2 </b>Autorización
            </p>
          </div>
        </div>
      )}

      {current_step_natural === 1 ? <StepTwo /> : <StepThree />}
    </>
  );
};
