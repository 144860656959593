import React from "react";
// Styles
import styles from "../styles/views/login.module.css";

export const Menu = () => {
  return (
    <nav className={styles.menu}>
      <ul>
        <li className={styles.text}>Inicio</li>
        <li>Beneficios y Servicios</li>
        <li>¿Quiénes somos?</li>
        <li> Eventos</li>
        <li>Actualidad</li>
        <li>Transacciones</li>
      </ul>
    </nav>
  );
};
