import React, { useState } from "react";
// Styles
import styles from "../styles/views/consulta.module.css";
// Images
import documento from "../img/icons/documento.svg";
// Redux
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { backStepTwoJuridica, continueStepOneNatural, continueStepTwoJuridica, setFileCedula } from "../redux/modules/steps";
import { useParams } from "react-router";
// Helpers
import { getError } from "../helpers/messagesSwal";

export const StepTwo = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [fileLoaded, setFileLoaded] = useState(false);

  const handleContinueOnClick = () => {
    if (!fileLoaded) {
      getError("Debe cargar el adjunto Solicitado");
      return;
    }

    if (type === "Natural") dispatch(continueStepOneNatural());
    else dispatch(continueStepTwoJuridica());
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: "application/pdf, image/png, image/jpg",
    onDrop: (acceptedFiles) => {
      setFileLoaded(true);
      dispatch(setFileCedula(acceptedFiles[0]));
    },
    onDropRejected: () => {
      setFileLoaded(false);
      getError("El archivo debe tener uno de las formatos validos(png, jpg, pdf)");
    },
  });

  return (
    <>
      <div className={styles.step_two}>
        <div className={styles.instructions}>
          <p>
            Adjunta la Cedula del representante legal de la empresa a consultar: <br />
            <br />
            - Recuerda adjuntar las dos caras del documento.
            <br />- Debe ser adjuntada en formatos: PDF, JPG, PNG.
          </p>
        </div>

        <section className={styles.hov} id={styles.zonadestino}>
          <div {...getRootProps()}>
            <img src={documento} alt="" />
            <input {...getInputProps()} />
            {acceptedFiles.length === 1 ? <p>{acceptedFiles[0].name}</p> : <p>Haz click para adjuntar el archivo</p>}
          </div>
        </section>
      </div>

      <div className={`${styles.accept_terms_button} mt-2`}>
        <div className={styles.buttons_terms}>
          {type !== "Natural" && (
            <button onClick={() => dispatch(backStepTwoJuridica())} className="button_secondary">
              Volver
            </button>
          )}
          <button onClick={handleContinueOnClick} className="button_primary">
            Continuar
          </button>
        </div>
      </div>
    </>
  );
};
