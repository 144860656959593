import { createSelector } from 'reselect';

// TYPES
const SET_ERROR = '[ERRORS]/SET_ERROR';
const RESET_ERROR = '[ERRORS]/RESET_ERROR';

const initialState = {
    error: null
}

// REDUCER
export default function errorReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case RESET_ERROR:
            return initialState;
        default:
            return state;
    }
}

// SELECTORS
export const getErrors = state => state.errors;
const getAuth = state => state.auth;

export const getErrorAuth = createSelector(
    [getErrors, getAuth],
    (errors, { loading }) => ({ ...errors, loading })
);

// ACTIONS
export const setError = payload => ({ type: SET_ERROR, payload });
export const resetError = () => ({ type: RESET_ERROR });
//EPICS