import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setTable } from "../redux/modules/admin";
// Styles
import styles from "../styles/views/admin_revision.module.css";
// Images
import cancel from "../img/icons/cancel.svg";

export const Table = ({ title, idName }) => {

  const [rows, setRows] = useState([]);
  const [selOptions] = useState([
    { key: 0, label: "Tarjetas De Credito", value: "tdc", disabled: false },
    { key: 1, label: "Sector financiero", value: "sf", disabled: false },
    { key: 2, label: "Sector real", value: "sr", disabled: false },
    { key: 3, label: "Otros", value: "otros", disabled: false },
    { key: 4, label: "Total", value: "total", disabled: false },
  ]);

  const { register } = useForm({ mode: "onBlur" });
  const dispatch = useDispatch();
  const dispathSetTable = (table) => {
    dispatch(setTable({ name: idName, table: table }));
  };

  const handleChange = (idx) => (e) => {
    const { name, value } = e.target;
    const _rows = [...rows];
    _rows[idx] = {
      ...rows[idx],
      [name]: name === "PaqueteInformacion" ? selOptions.filter((v) => v.value === value)[0].label : value,
    };
    setRows(_rows);
    dispathSetTable(_rows);
  };

  const handleAddRow = () => {
    const item = {
      NumeroObligaciones: 0,
      PaqueteInformacion: selOptions.filter((v) => v.value === 'tdc')[0].label,
      NumeroObligacionesDia: 0,
      SaldoObligacionesDia: 0,
      CantidadObligacionesMora: 0,
      SaldoObligacionesMora: 0,
    };
    setRows([...rows, item]);
    dispathSetTable([...rows, item]);
  };

  const handleRemoveSpecificRow = (idx) => () => {
    const _rows = [...rows];
    _rows.splice(idx, 1);
    setRows(_rows);
    dispathSetTable(_rows);
  };

  return (
    <div>
      <div className={styles.text_img}>
        <p>{title}</p>
        <div>
          <button onClick={handleAddRow} className="button_primary">
            Agregar fila
          </button>
        </div>
      </div>
      <div className={styles.table}>
        <table className="table table-bordered table-hover" id="tab_logic">
          <thead>
            <tr>
              <th className="text-center"> # </th>
              <th className="text-center"> Cantidad </th>
              <th className="text-center"> Tipo de obligación </th>
              <th className="text-center"> Al día</th>
              <th className="text-center"> Valor</th>
              <th className="text-center"> En mora</th>
              <th className="text-center"> Valor</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {rows.map((item, idx) => (
              <tr id="addr0" key={idx}>
                <td>{idx + 1}</td>
                <td>
                  <input type="number" name="NumeroObligaciones" min="0" value={item.NumeroObligaciones} onChange={handleChange(idx)} />
                </td>
                <td>
                  <div>
                    <select className={styles.selectCifin} name="PaqueteInformacion" ref={register({ validate: (value) => parseInt(value) > 0 })} defaultValue="tdc" onChange={handleChange(idx)}>
                      {selOptions.map((opt) => (
                        <option key={opt.key} value={opt.value} disabled={opt.disabled}>
                          {opt.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    name="NumeroObligacionesDia"
                    min="0"
                    value={item.NumeroObligacionesDia}
                    onChange={handleChange(idx)}
                    className="inputCifin" />
                </td>
                <td>
                  <input
                    type="number"
                    name="SaldoObligacionesDia"
                    min="0"
                    value={item.SaldoObligacionesDia}
                    onChange={handleChange(idx)}
                    className="inputCifin" />
                </td>
                <td>
                  <input
                    type="number"
                    name="CantidadObligacionesMora"
                    min="0"
                    value={item.CantidadObligacionesMora}
                    onChange={handleChange(idx)}
                    className="inputCifin" />
                </td>
                <td>
                  <input
                    type="number"
                    name="SaldoObligacionesMora"
                    min="0"
                    value={item.SaldoObligacionesMora}
                    onChange={handleChange(idx)}
                    className="inputCifin" />
                </td>
                <td>
                  <button className="btn btn-outline-danger btn-sm" onClick={handleRemoveSpecificRow(idx)}>
                    <img alt="cancel" src={cancel} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
