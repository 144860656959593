import React, { useState } from "react";
// Styles
import styles from "../styles/views/consulta.module.css";
// Images
import archivo from "../img/icons/archivo.svg";
// Redux
import { useDispatch } from "react-redux";
import { continueStepOneJuridica, setFileCamaraComercio } from "../redux/modules/steps";
// Helpers
import { useDropzone } from "react-dropzone";
import { getError } from "../helpers/messagesSwal";

export const StepOne = () => {
  const dispatch = useDispatch();
  const [fileLoaded, setFileLoaded] = useState(false);

  const handleOnClick = () => {
    if (!fileLoaded) {
      getError("Debe cargar el adjunto Solicitado");
      return;
    }
    dispatch(continueStepOneJuridica());
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    accept: "application/pdf, image/png, image/jpg",
    onDrop: (acceptedFiles) => {
      setFileLoaded(true);
      dispatch(setFileCamaraComercio(acceptedFiles[0]));
    },
    onDropRejected: () => {
      setFileLoaded(false);
      getError("El archivo debe tener uno de las formatos validos(png, jpg, pdf)");
    },
  });

  return (
    <>
      <div className={styles.step_one}>
        <div className={styles.instructions}>
          <p>
            Adjunta la Cámara y Comercio de la empresa a consultar, también debes tener en cuenta:
            <br />
            <br />
            - Debe tener máximo 90 días de antiguedad.
            <br />- Debe ser adjuntada en formatos: PDF, JPG, PNG.
          </p>
        </div>
        <section className={styles.hov} id={styles.zonadestino}>
          <div {...getRootProps()}>
            <img src={archivo} alt="" />
            <input {...getInputProps()} />
            {acceptedFiles.length === 1 ? <p>{acceptedFiles[0].name}</p> : <p>Haz click para adjuntar el archivo</p>}
          </div>
        </section>
      </div>
      <button onClick={handleOnClick} className="button_primary button_table">
        Continuar
      </button>
    </>
  );
};
