import React from "react";

export const Loader = () => {
  return (
    <div className="container_loader">
      <div className="loading">
        <div className="loader"></div>
        <p>Espera un momento por favor</p>
      </div>
    </div>
  );
};
