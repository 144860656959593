import { createSelector } from 'reselect';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { resetError, setError } from './errors';
import { resetState } from './admin';
import { resetData } from './data';
import { resetResponse } from './steps';

// TYPES
const START_LOGIN = '[AUTH]/START_LOGIN';
const START_LOGOUT = '[AUTH]/START_LOGOUT';
const LOGIN_SUCCESS = '[AUTH]/LOGIN_SUCCESS';
const FINISH_LOADING = '[AUTH]/FINISH_LOADING';
const LOGOUT = '[AUTH]/LOGOUT';

// REDUCER
export default function authReducer(state = { isAuthenticated: false, error: null }, action) {
    switch (action.type) {
        case START_LOGIN:
            return {
                ...state,
                loading: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload.response,
                access_token: action.payload.access_token,
                isAuthenticated: true,
                loading: false
            };

        case FINISH_LOADING:
            return { ...state, loading: false }
        case LOGOUT:
            return { isAuthenticated: false }
        default:
            return state;
    }
}

// SELECTORS
export const getAuth = state => state.auth

export const getAuthProps = createSelector(
    getAuth,
    (data) => (data)
);

// ACTIONS
export const startLogin = data => ({ type: START_LOGIN, payload: data });
export const startLogOut = () => ({ type: START_LOGOUT });
const logOut = () => ({ type: LOGOUT });
const loginSuccess = payload => ({ type: LOGIN_SUCCESS, payload });
const finishLoading = () => ({ type: FINISH_LOADING });


//EPICS
export const fetchLoginEpic = action$ => action$.pipe(
    ofType(START_LOGIN),
    mergeMap(action =>
        ajax.post(`${process.env.REACT_APP_URL_API}/autenticacion`, JSON.stringify(action.payload)).pipe(
            mergeMap(({ response }) => of(resetError(), loginSuccess(response))),
            catchError(({ status, response }) => {
                if (status === 400 || status === 500)
                    return of(setError(response?.error), finishLoading());
                return of(setError('No se logro iniciar las sesión'), finishLoading());
            })
        )
    )
);

export const logOutEpic = action$ => action$.pipe(
    ofType(START_LOGOUT),
    mergeMap(() => (of(logOut(), resetState(), resetData(), resetResponse())))
);