import React from "react";
// Styles
import styles from "../../styles/views/login.module.css";
// Images
import banner from "../../img/banners/login.png";
import logoFenalco from "../../img/logos/logo_fenalco.svg";
// Hooks
import { useForm } from "react-hook-form";
// Redux
import { useDispatch } from "react-redux";
import { startLogin } from "../../redux/modules/auth";
// Components
import { Contact } from "../../components/ContactComponent";
import { Menu } from "../../components/MenuComponent";

export const Login = () => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    defaultValues: { usuario: "", clave: "" },
  });

  const dispatch = useDispatch();
  const onSubmit = (data) => dispatch(startLogin(data));

  return (
    <div className={styles.container}>
      <div className="logo">
        <img src={logoFenalco} alt="" />
      </div>
      <Contact />
      <Menu />
      <div className={styles.img_banner}>
        <img src={banner} alt="" />
      </div>

      <div className={styles.text_banner}>
        <h3>
          Actívate con
          <br />
          nosotros
        </h3>
        <p>
          En Procrédito Activamos el servicio de Consultas de nuestra Central de Riesgos para los empresarios que quieran usar nuestra información rápido, fácil y sin complicaciones.
          <br />
          Sin cargos fijos mensuales ni límite de consumos; el empresario solo paga por lo que utilice a un precio ajustado a la realidad del comercio.
        </p>
      </div>
      <div className={styles.login}>
        <h4>¡Bienvenido!</h4>
        <p>A tu portal Procrédito donde podrás realizar tus consultas.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input">
            <i className="fas fa-envelope"></i>
            <input type="text" name="usuario" className={errors.usuario && "input_error"} ref={register({ required: true })} placeholder="Usuario o correo electrónico" />
          </div>
          <div className="input">
            <i className="fas fa-lock"></i>
            <input type="password" className={errors.clave && "input_error"} ref={register({ required: true })} name="clave" placeholder="Contraseña" />
          </div>
          <button type="submit" className="button_primary">
            ¡Bienvenido!
          </button>
        </form>
        <a href="https://procredito.fenalcoantioquia.com/modulo_seguridad/formularios/frm_recuperacion_contrasena.php">¿Olvidaste tu contraseña?</a> <br />
      </div>
    </div>
  );
};
