import React, { useEffect } from "react";
// Styles
import styles from "../../styles/views/admin_revision.module.css";
// Images
import logoFenalco from "../../img/logos/logo_fenalco.svg";
import headerHome from "../../img/banners/header_home.png";
import back from "../../img/icons/atras.svg";
// Router
import { useParams } from "react-router";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDataUserById, resetInfoUser } from "../../redux/modules/data";
import { clickInReject, startSubmitReject, getDataAdmin, resetState, startSubmitApprove, clearError } from "../../redux/modules/admin";
// Components
import Iframe from "react-iframe";
import { Loader } from "../../components/LoaderComponent";
import { getError, getReject, getSuccess } from "../../helpers/messagesSwal";

export const RevisionAdmin = ({ history }) => {
  const dispatch = useDispatch();
  const { current_user, loading, showModal, error, count, isApproved, isRejected, token, loading_admin } = useSelector(getDataAdmin);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getDataUserById(id));
  }, [id, dispatch]);

  if (error && count <= 1) {
    getError(error.response ? error.response.error : error.message);
    dispatch(clearError());
  } else if (error && count > 1) {
    getError("No se logró conectar con los servicios CIFIN, debe ingresar estos datos de manera manual.", count).then(() => {
      dispatch(resetState());
      dispatch(clearError());
      history.push(`/cifinRevision/${id}`);
    });
  }

  if (isRejected) {
    getSuccess(isRejected).then(() => {
      dispatch(resetState());
      history.push("/admin");
    });
  }

  if (isApproved) {
    getSuccess(isApproved).then(() => {
      dispatch(resetState());
      history.push("/admin");
    });
  }

  if (showModal) {
    getReject("Escriba el motivo por el cual rechaza la consulta").then(({ isConfirmed, mensaje }) => isConfirmed && dispatch(startSubmitReject({ id, mensaje })));
  }

  const handleRechazarOnClick = () => {
    dispatch(clickInReject());
  };

  const handleAprobarOnClick = () => {
    dispatch(
      startSubmitApprove({
        id,
        apellido: current_user?.primer_apellido,
        documento: current_user?.documento_consultado,
        tipo_documento: current_user?.tipo_documento,
        user: current_user?.usuario,
        nit: current_user?.nit,
      })
    );
  };

  const handleBackOnClick = () => {
    dispatch(resetInfoUser());
    history.goBack();
  };

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.header_back}>
        <img src={headerHome} alt="" />
      </div>
      <div className="logo">
        <img src={logoFenalco} alt="" />
      </div>
      <div className={styles.data_review}>
        {loading || loading_admin ? (
          <Loader />
        ) : (
          <>
            <div className={styles.header_back}>
              <button onClick={handleBackOnClick} className={styles.back} type="">
                <img src={back} alt="" />
              </button>
              <h4>Revisión</h4>
            </div>
            <div className={styles.data_inf}>
              <div className={`card ${styles.card}`}>
                <div className="information">
                  <span>Tipo de consulta</span>
                  <p>{current_user?.tipo_consulta}</p>
                </div>
                <div className="information">
                  <span>Usuario</span>
                  <p>{current_user?.usuario}</p>
                </div>
                <div className="information">
                  <span>Fecha de consulta</span>
                  <p>{current_user?.fecha}</p>
                </div>
                <div className="information">
                  <span>Hora de consulta</span>
                  <p>{current_user?.hora}</p>
                </div>
              </div>
              <div className={styles.button_review}>
                <button onClick={handleRechazarOnClick} className={`button_primary ${styles.cancel}`}>
                  Rechazar
                </button>
                <button onClick={handleAprobarOnClick} className={`button_primary  ${count >= 1 ? styles.resend : ""}`}>
                  {count >= 1 ? "Reintentar" : "Aprobar"}
                </button>
              </div>
            </div>
            <div className={styles.cards_inf}>
              {current_user?.tipo_consulta === "Juridica" && (
                <div className={styles.card_img}>
                  <div className={styles.text_img}>
                    <p>1 Cámara y comercio</p>
                    <a rel="noreferrer" target="_blank" href={`${current_user?.path_camara_comercio}&authorization=Bearer ${token}`}>
                      Ver
                    </a>
                  </div>
                  <div className={styles.img_card}>
                    {current_user?.camara_comercio_is_pdf ? (
                      <Iframe width="100%" height="260px" src={`${current_user?.path_camara_comercio}&authorization=Bearer ${token}`} />
                    ) : (
                      <img src={`${current_user?.path_camara_comercio}&authorization=Bearer ${token}`} alt="" />
                    )}
                  </div>
                </div>
              )}

              <div className={styles.card_img}>
                <div className={styles.text_img}>
                  <p>2 Fotocopia cédula</p>
                  <a rel="noreferrer" target="_blank" href={`${current_user?.path_cedula}&authorization=Bearer ${token}`}>
                    Ver
                  </a>
                </div>
                <div className={styles.img_card}>
                  {current_user?.cedula_is_pdf ? (
                    <Iframe width="100%" height="260px" src={`${current_user?.path_cedula}&authorization=Bearer ${token}`} />
                  ) : (
                    <img src={`${current_user?.path_cedula}&authorization=Bearer ${token}`} alt="" />
                  )}
                </div>
              </div>
              {current_user?.tipo_consulta === "Natural" && (
                <div className={`${styles.card_img} ${styles.card_signature2}`}>
                  <div className={styles.text_img}>
                    <p>2 Autorización</p>
                  </div>
                  <div className={`card ${styles.card}`}>
                    <div className="information">
                      <span>Autoriza</span>
                      <p>{`${current_user?.nombres} ${current_user?.primer_apellido} ${current_user?.segundo_apellido}`}</p>
                    </div>
                    <div className="information">
                      <span>Con número de documento</span>
                      <p>{current_user?.documento_consultado}</p>
                    </div>
                  </div>
                  <div className={styles.signature}>
                    <a rel="noreferrer" target="_blank" href={`${current_user?.path_firma}&authorization=Bearer ${token}`}>
                      Ver
                    </a>
                    {current_user?.firma_is_pdf ? (
                      <Iframe width="100%" height="300px" src={`${current_user?.path_firma}&authorization=Bearer ${token}`} />
                    ) : (
                      <img src={`${current_user?.path_firma}&authorization=Bearer ${token}`} alt="" />
                    )}
                  </div>
                </div>
              )}
            </div>
            {current_user?.tipo_consulta === "Juridica" && (
              <div className={`${styles.card_img} ${styles.card_signature1}`}>
                <div className={styles.text_img}>
                  <p>3 Autorización</p>
                  <a style={{ marginRight: 10 }} rel="noreferrer" target="_blank" href={`${current_user?.path_firma}&authorization=Bearer ${token}`}>
                    Ver
                  </a>
                </div>
                <div className={styles.img_signature}>
                  <div className={`card ${styles.card}`}>
                    {current_user?.nombres === undefined && (
                      <div className="information">
                        <>
                          <span>Autoriza</span>
                          <p>{`${current_user?.nombres} ${current_user?.primer_apellido} ${current_user?.segundo_apellido}`}</p>
                        </>
                      </div>
                    )}
                    <div className="information">
                      <span>Con número de documento</span>
                      <p>{current_user?.documento_consultado}</p>
                    </div>
                  </div>
                  <div className={styles.signature}>
                    {current_user?.firma_is_pdf ? (
                      <Iframe width="100%" height="75px" src={`${current_user?.path_firma}&authorization=Bearer ${token}`} />
                    ) : (
                      <img src={`${current_user?.path_firma}&authorization=Bearer ${token}`} alt="" />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
